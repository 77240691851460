function recaptchaCallback() {
    $('#f_recaptcha').valid();
}



/* Carousel Homepage */

$('.owl-homepage').owlCarousel({
    loop:true,
    nav:false,
    margin: 30,
    items: 1,
    dots: false,
    smartSpeed: 2000,
    responsive : {
        0 : {
            dots: true,
        },
        768 : {
            dots: false,
        },
    }
});

$(".prevBtn").click(function() {
    $('.owl-homepage').trigger('prev.owl.carousel');
});

$(".nextBtn").click(function() {
    $('.owl-homepage').trigger('next.owl.carousel');
});

$('.owl-sliderDouble').owlCarousel({
    loop:true,
    nav:true,
    margin: 30,
    items: 2,
    dots: false,
    navText: ["<img src='/assets/aunisceanes/images/leftArrowDouble.png'>","<img src='/assets/aunisceanes/images/rightArrowDouble.png'>"],
    responsive : {
        0 : {
            items: 1,
        },
        575 : {
            items: 2,
        },

    }
});

$('.owl-secondSlider').owlCarousel({
    loop:true,
    nav:false,
    margin: 30,
    items: 1,
    dots: false,
    smartSpeed: 2000,
    responsive : {
        0 : {
            dots: true,
        },
        768 : {
            dots: false,
        },
    }
});

$(".prevBtn").click(function() {
    $('.owl-secondSlider').trigger('prev.owl.carousel');
});

$(".nextBtn").click(function() {
    $('.owl-secondSlider').trigger('next.owl.carousel');
});

/* Carousel Fiche*/

$('.owl-fiche').owlCarousel({
    loop:true,
    nav:true,
    margin: 30,
    items: 1,
    dots: false,
    navText: ["<img src='/assets/aunisceanes/images/leftArrowHP.png'>","<img src='/assets/aunisceanes/images/rightArrowHP.png'>"],
    smartSpeed: 2000,
    responsive : {
        0 : {
            nav: false,
            dots: true,
        },
        991 : {
            nav: true,
        },
    }
});

/* Carousel en image HP */

/* Carousel block slider */
$('.owl-slider').owlCarousel({
    loop:true,
    nav:false,
    margin: 30,
    items: 1,
    dots: false,
    smartSpeed: 2000,
    responsiveClass: true,
    responsive: {
        0 : {
            dots: true,
        },
        768 : {
            dots: false,
        },
    }
});

$(".prevBtn").click(function() {
    $('.owl-slider').trigger('prev.owl.carousel');
});

$(".nextBtn").click(function() {
    $('.owl-slider').trigger('next.owl.carousel');
});

/* Carousel Footer */

$('.owl-footer').owlCarousel({
    loop:true,
    nav:false,
    margin: 30,
    items: 1,
    dots: false,
    responsive : {
        0 : {
            loop:true,
            nav:true,
            items: 1,
            dots: true,
        },
        768 : {
            items: 3,
            mouseDrag: true,
            pullDrag: true,
            touchDrag: true,
            dots: true,
        },
        991 : {
            loop:false,
            nav:false,
            items: 5,
            mouseDrag: false,
            pullDrag: false,
            touchDrag: false,
        }
    }
});

$(document).ready(function() {

    // MENU BURGER

    $('.toggleMenu').click(function() {
        $(this).toggleClass('active');
        $('.menu-mobile').toggleClass('active');
        $('.content-bg').toggleClass('active');
    })

    var sync1 = $("#sync1");
    var sync2 = $("#sync2");
    var slidesPerPage = 12; //globaly define number of elements per page
    var syncedSecondary = true;

    sync1.owlCarousel({
        items : 1,
        slideSpeed : 2000,
        nav: false,
        dots: false,
        loop: false,
        animateOut: 'fadeOut',
        responsiveRefreshRate : 200,
    }).on('changed.owl.carousel', syncPosition);

    sync2
        .on('initialized.owl.carousel', function () {
        sync2.find(".owl-item").eq(0).addClass("current");
        })
        .owlCarousel({
        items : 3,
        //center: true,
        nav: false,
        loop: false,
        dots: false,
        mouseDrag: false,
        pullDrag: false,
        touchDrag: false,
        freeDrag: false,
        smartSpeed: 200,
        slideSpeed : 500,
        slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
        responsiveRefreshRate : 100,
    }).on('changed.owl.carousel', syncPosition2);

    function syncPosition(el) {
        //if you set loop to false, you have to restore this next line
        var current = el.item.index;

        //if you disable loop you have to comment this block
        // var count = el.item.count-1;
        // var current = Math.round(el.item.index - (el.item.count/2) - .5);

        // if(current < 0) {
        //   current = count;
        // }
        // if(current > count) {
        //   current = 0;
        // }

        //end block

        sync2
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");
        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        if (current > end) {
        sync2.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
        sync2.data('owl.carousel').to(current - onscreen, 100, true);
        }
    }

    function syncPosition2(el) {
        if(syncedSecondary) {
        var number = el.item.index;
        sync1.data('owl.carousel').to(number, 100, true);
        }
    }

    sync2.on("click", ".owl-item", function(e){
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
        sync2.data('owl.carousel').to(number, 300, true);
    });

    // FICHE FORMULAIRE
    $('.rappel').click(function() {

        $(this).addClass('active');
        $('.plansForm').removeClass('active');
        $('.plans').removeClass('active');

       $('.rappelForm').addClass('active');

        if($('.rappelForm').hasClass('active')) {

            $('.rappelForm').css('z-index', '1');

        } else {

             $('.rappelForm').css('z-index', '0');

        }

        if($('.plansForm').hasClass('active')) {

            $('.plansForm').css('z-index', '1');

        } else {

           $('.plansForm').css('z-index', '0');

        }
    });

    $('.plans').click(function() {
        $(this).addClass('active');

       $('.rappel').removeClass('active');

       $('.rappelForm').removeClass('active');

       $('.plansForm').addClass('active');

        if($('.plansForm').hasClass('active')) {

            $('.plansForm').css('z-index', '1');

        } else {

           $('.plansForm').css('z-index', '0');

        }

        if($('.rappelForm').hasClass('active')) {

            $('.rappelForm').css('z-index', '1');

        } else {

             $('.rappelForm').css('z-index', '0');

        }
    });

    // SIMULATEUR DE PROJET

    $('.houseButton').click(function() {
        $('.houseButton').parent().removeClass('active');
        $(this).parent().addClass('active')
    })

    $('.houseButton1').click(function() {
        $('.houseButton1').parent().removeClass('active');
        $(this).parent().addClass('active')
    })

    $('.houseButton2').click(function() {
        $('.houseButton2').parent().removeClass('active');
        $(this).parent().addClass('active')
    })

    $('.houseButton3').click(function() {
        $('.houseButton3').parent().removeClass('active');
        $(this).parent().addClass('active')
    })

    $('.houseButton4').click(function() {
        $('.houseButton4').parent().removeClass('active');
        $(this).parent().addClass('active')
    })

    $('.btnStep').click(function() {

        // Récupérer la bonne étape
        var step = $(this).attr('data-step');
        var prevStep = step-1;

        // Récupérer l'élément sur lequel on veut appliquer la height
        $('.simulationCard').removeClass('active');
        $('.HouseForm').each(function() {


            if($(this).attr('data-step') == step) {
                $(this).parent().parent('.simulationCard').addClass('active');
                // Récupère la height
                var height = $(this).outerHeight() + 60;

                // Appliquer la height
                $(this).parent('.contentCard').css({
                    'height': height,
                    'padding': 30
                });
            }

            // Gérer la height de l'élément précedent celui actif
            if($(this).attr('data-step') == prevStep) {
                $(this).parent('.contentCard').css({
                    'height': 0,
                    'padding': 0
                });
            }
        })
    });

    $('.cancel').click(function() {

        var step = $(this).attr('data-step');
        var nextStep = parseInt(step)+1;

        $('.simulationCard').removeClass('active');

        $('.HouseForm').each(function() {

            if($(this).attr('data-step') == nextStep) {
                $(this).parent('.contentCard').css({
                    height: 0,
                    padding: 0
                })
            }

            if($(this).attr('data-step') == step) {
                var height = $(this).outerHeight(true) + 60;
                $(this).parent().parent('.simulationCard').addClass('active')
                $(this).parent('.contentCard').css({
                    height: height,
                    padding: 30
                })
            }
        })

    })

    $.validator.addMethod("validateRecaptcha", function (value, element) {
          if (grecaptcha.getResponse() == '') {
              return false;
          } else {
              return true;
          }
      }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#container_form form, .simulatorForm').length) {
          $('#contactRgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#contactRgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form, .simulatorForm').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            } else if (element.attr('type') === undefined) {
                var parentElm = element.closest('.form-group');
                error.addClass("select");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
});
